<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-airport</v-icon>
          AEROPUERTOS
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-5"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="incluirInactivos"
          label="Incluir inactivos"
          @change="cargarLista"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="abrirDialogAeropuerto(null)">
          <v-icon>mdi-plus-circle-outline</v-icon> Nuevo aeropuerto
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :search="search"
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn
            icon
            color="green"
            @click="abrirDialogAeropuerto(item.AEROPUERTO_ID)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            icon
            color="red"
            @click="eliminarAeropuerto(item.AEROPUERTO_ID)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.ACTIVO="{ item }">
          {{ item.ACTIVO == "1" ? "ACTIVO" : "INACTIVO" }}
        </template>
      </v-data-table>
    </v-card>
    <!-- dialogo aeropuerto -->
    <v-dialog v-model="dialogAeropuerto" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <v-icon class="mb-1">mdi-airport</v-icon>
          <span class="headline"
            >Aeropuerto | {{ datosAeropuerto.NOTIFY_ID }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formAeropuerto">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosAeropuerto.NOTIFY_NOMBRE"
                    label="Nombre "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-select
                    clearable
                    :items="tiposIdent"
                    item-text="DESCRIPCION"
                    item-value="ID"
                    v-model="datosAeropuerto.TIPO_IDENT"
                    label="Tipo de identificación"
                  ></v-select>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="datosAeropuerto.NOTIFY_RUC"
                    label="Ruc"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="datosAeropuerto.NOTIFY_TELEFONO"
                    label="Teléfono"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="datosAeropuerto.NOTIFY_CELULAR"
                    label="Celular"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    :rules="validarEmail1"
                    v-model="datosAeropuerto.NOTIFY_EMAIL"
                    label="Email principal"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    :rules="validarEmail2"
                    v-model="datosAeropuerto.NOTIFY_EMAIL2"
                    label="Email secundario"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="datosAeropuerto.NOTIFY_DIRECCION"
                    label="Dirección"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-autocomplete
                    :rules="requiredRule"
                    :items="paises"
                    item-text="NOMBRE"
                    item-value="PAIS_CODIGO"
                    v-model="datosAeropuerto.PAIS_COD"
                    label="Pais"
                    @change="cambiarCodigoPais"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="guardarAeropuerto">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;&nbsp;
            <v-btn small @click="dialogAeropuerto = !dialogAeropuerto">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "AeropuertoComponent",
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Codigo", value: "AP_COD" },
      { text: "Nombre", value: "AP_NOMBRE" },
      { text: "Activo", value: "ACTIVO" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        String(v)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "Ingrese un Email válido",
    ],
    lista: [],
    options: {},
    dialog: false,
    notifications: false,
    sound: true,
    widgets: true,
    search: null,
    incluirInactivos: false,

    dialogAeropuerto: false,
    datosAeropuerto: {
      AEROPUERTO_ID: "",
      AP_NOMBRE: "",
      AP_COD: "",
      CIUPA_ID: "",
      ACTIVO: 1,
    },

    paises: [],
    itemsPerPage: 20,
    creaAp: true,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user"]),
    ...mapGetters("access", [""]),
    validarEmail1() {
      return this.validarEmail(this.datosAeropuerto.NOTIFY_EMAIL);
    },
    validarEmail2() {
      return this.validarEmail(this.datosAeropuerto.NOTIFY_EMAIL2);
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    validarEmail(email) {
      if (email == "") {
        return [true];
      }
      if (email == null) {
        return [true];
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-aeropuertos");
      this.requestApi({
        method: "GET",
        data: {
          incluirInactivos: this.incluirInactivos,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data;
          this.pageCount = 10;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    modificarAeropuerto() {
      this.setUrl("api/modificar-aeropuerto");
      this.requestApi({
        method: "POST",
        data: {
          aeropuerto: this.datosAeropuerto,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarLista();
          this.dialogAeropuerto = false;
        })
        .then(() => {});
    },

    abrirDialogAeropuerto(apid) {
      this.creaAp = false;
      if (apid == null) {
        this.datosAeropuerto = {
          NOTIFY_ID: "",
          NOTIFY_NOMBRE: "",
          NOTIFY_DIRECCION: "",
          NOTIFY_TELEFONO: "",
          PAIS_COD: "",
          TIPO_IDENT: 0,
          ACTIVO: 1,
        };
        this.creaAp = true;
      }
      this.setUrl("api/aeropuerto");
      this.requestApi({
        method: "GET",
        data: {
          AEROPUERTO_ID: apid,
        },
      })
        .then((res) => {
          if (res.data.notify != null) {
            this.datosAeropuerto = res.data.aeropuerto;
          }
          this.paises = res.data.pais;
          this.dialogAeropuerto = true;
        })
        .then(() => {});
    },

    eliminarAeropuerto(aeropuertoId) {
      Vue.swal({
        html: "Está seguro de eliminar el aeropuerto ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-aeropuerto");
          this.requestApi({
            method: "POST",
            data: {
              AEROPUERTO_ID: aeropuertoId,
            },
          }).then((res) => {
            //console.log(res);
            this.cargarLista();
            this.alertNotification({ param: { html: res.data.msg } });
          });
        }
      });
    },
    guardarAeropuerto() {
      if (!this.$refs.formAeropuerto.validate()) {
        return false;
      }
      if (this.creaAp == true) {
        this.crearAeropuerto();
      } else {
        this.modificarAeropuerto();
      }
    },
    crearNotify() {
      console.log(this.datosAeropuerto);
      this.setUrl("api/crear-aeropuerto");
      this.requestApi({
        method: "POST",
        data: {
          aeropuerto: this.datosAeropuerto,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.dialogAeropuerto = false;
          this.cargarLista();
        })
        .then(() => {});
    },
    cambiarCodigoPais() {
      //this.datosAeropuerto.SHP_PAIS = this.datosAeropuerto.PAIS_COD;
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Aeropuertos");
    this.cargarLista();
  },
};
</script>
