import { render, staticRenderFns } from "./Aeropuertos.vue?vue&type=template&id=b54d6d3c&"
import script from "./Aeropuertos.vue?vue&type=script&lang=js&"
export * from "./Aeropuertos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports